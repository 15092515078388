
import { Vue, Options } from "vue-class-component";
import { useStore } from "../store";
import SearchTransaction from "../service/SearchTransaction";
import RegisterService from "../service/RegisterService";
import Toaster from "../helpers/Toaster";
import moment from "moment";

@Options({
  components: {},
  emits: ["searchReceiptEvent"],
})
export default class SearchTransactions extends Vue {
  private store = useStore();
  private searchService;
  private toast;
  private myStores = [
    {
      mall: '',
      code: ''
    }
  ];

  private orderLists = [];
  private registerService;

  private filterDates = [
    { name: "None", value: "None" },
    { name: "Today", value: "today" },
    { name: "This Week", value: "this_week" },
    { name: "This Month", value: "this_month" },
    { name: "Last Week", value: "last_week" },
    { name: "Last Month", value: "last_month" },
    { name: "Next Week", value: "next_week" },
    { name: "Next Month", value: "next_month" },
    { name: "Last Three Months", value: "last_three" },
    { name: "This Year", value: "this_year" },
    { name: "Last Year", value: "last_year" },
  ];

  private filterTypes = [
    { name: "All Receipts", value: "all" },
    { name: "Rack Receipts", value: "rack" },
    { name: "In Progress", value: "progress" },
    { name: "Ready Receipts", value: "ready" },
    { name: "Refund Receipts", value: "refund" },
    { name: "Buyback Receipts", value: "buyback" },
    { name: "Redo Receipts", value: "redo" },
    { name: "Pay Later Receipts", value: "paylater" },
    { name: "Split Receipts", value: "split" },
    { name: "Void Receipts", value: "void" },
    { name: "Corporate Receipts", value: "corporate" },
    { name: "Referral Receipts", value: "referral" },
    { name: "Walk in Receipts", value: "walk in" },
  ];

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.searchService = new SearchTransaction();
    this.registerService = new RegisterService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getStoresList();
  }

  private searchReceipt = {
    receiptNO: "",
    storeObj: {},
    searchFilter: { name: "None", value: "None" },
    searchFilterType: { name: "All Receipts", value: "all" },
    dateFrom: "",
    dateTo: "",
  };

  searchReceiptDetails() {
    this.searchReceipt.dateFrom = this.formatDate(this.searchReceipt.dateFrom);
    this.searchReceipt.dateTo = this.formatDate(this.searchReceipt.dateTo);
    this.searchService.searchItem(this.searchReceipt).then((data) => {
      this.$emit("searchReceiptEvent", data);
    });
  }

  clearForCustomDates() {
    this.searchReceipt.receiptNO = "";
    this.searchReceipt.searchFilter = { name: "None", value: "None" };
  }

  clearForFilters() {
    this.searchReceipt.receiptNO = "";
    this.searchReceipt.dateFrom = "";
    this.searchReceipt.dateTo = "";
  }

  clearForCustomerSearch() {
    this.searchReceipt.searchFilter = { name: "None", value: "None" };
    this.searchReceipt.dateFrom = "";
    this.searchReceipt.dateTo = "";
  }

  getStoresList() {
    this.registerService.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
      this.searchReceipt.storeObj =  this.filterStore(data.storeCode);
    });
  }

  filterStore(storeCode)
  {
    let selectedStore = {mall: '', code: ''};

     this.myStores.filter(e => {
        if(e.code == storeCode)
        {
          selectedStore = {mall: e.mall, code: e.code};
        }
     });

    return selectedStore;
  }

  formatDate(value) {
    let reFormatedDate = "";

    if (value) {
      reFormatedDate = moment(value).format("YYYY-MM-DD");
    }

    return reFormatedDate;
  }
}
