
import { Options, Vue,mixins } from "vue-class-component";
import { useStore } from "../store";
import ReceiptPayement from "../service/ReceiptPayement";
import { camelCase } from "lodash";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";

interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
  transSource: string;
}


@Options({
  components: {},
})
export default class ReceiptPayment extends mixins(UtilityOptions){
  private store = useStore();
  private txnService;
  private toast;
  private receiptPayments: PaymentListType [] = [];


  created() {
    this.txnService = new ReceiptPayement();
    this.toast = new Toaster();
  }

  get receiptID() {
    return this.store.getters.getReceiptID;
  }

  mounted() {
    this.getReceiptPayments();
  }

  getReceiptPayments() {
    this.txnService.getPayments(this.receiptID).then((data) => {
      const receipt = this.camelizeKeys(data);
      this.receiptPayments = receipt.invoicePayment;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatAmount(amount) {
    amount = Number(amount);

    if (amount != " ") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  totalSaleAmount()
  {

    let total = 0;

     this.receiptPayments.forEach(e => {

       if(e.paymentType != 'Tip' && e.transSource == 'invoice')
       {
          total = total + e.transTotalAmount;
       }
        
     });

    return total.toFixed(2);
  }

  totalRefundAmount()
  {

    let total = 0;

     this.receiptPayments.forEach(e => {
        if(e.paymentType != 'Tip' && e.transSource == 'refund')
        {
            total = total + e.transTotalAmount;
        }
     });

    return total.toFixed(2);
  }

  totalBBAmount()
  {

    let total = 0;
     this.receiptPayments.forEach(e => {
        if(e.paymentType != 'Tip' && e.transSource == 'buyback')
        {
            total = total + e.transTotalAmount;
        }
     });

    return total.toFixed(2);
  }
}
