<template>
  <Dialog
    v-model:visible="productDialog"
    position="top"
    class="p-fluid  p-m-0 p-dialog-maximized"
    :closable="true"
    @hide="closeDialogBox"

  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
       <i class="pi pi-file-o"></i> {{ dialogTitle }}
      </h5>
    </template>
    <div class="row">
      <div class="modal-body">
        <div
          style="
            width: 600px;
            background-color: #fff;
            margin: 0 auto;
            border: 1px dotted #ccc;
            padding: 10px;
          "
        >
          <!-- CONTENT TABLE // -->
          <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
              <td valign="top" class="textContent">
                <h3
                  mc:edit="header"
                  style="margin: 0 auto; margin-bottom: 3px; text-align: center"
                >
                  <img
                    style="display: inline"
                    src="@/assets/images/black_logo.png"
                    alt=""
                  />
                </h3>
                <div class="receipt-header text-center">
                  {{ receiptData.branchCode }}
                </div>
                <div class="receipt-header text-center">
                  {{ receiptData.address }}
                </div>
                <div class="receipt-header text-center">
                  Postal code : {{ receiptData.postCode }}
                </div>
                <div class="receipt-header text-center">
                  Tel : {{ receiptData.telephone }}
                </div>
                <div class="receipt-header text-center">
                  <h3>  INV # {{ receiptID }} </h3>
                </div>
              </td>
            </tr>
            <tr>
              <td valign="top" class="textContent">
                <h3 mc:edit="header" class="text-center">
                  <b> {{ receiptData.invPayStatus }}</b>
                </h3>
                <h3 mc:edit="header">
                  {{ receiptData.customer }}
                </h3>
                <div class="receipt-header">
                  <span>{{receiptData.referralOrCorp}}</span>
                </div>
                <div class="receipt-header">
                  Tel : {{ receiptData.customerTel }}
                </div>
                <div class="receipt-header">
                  Date/Time : {{ receiptData.dateTime }}
                </div>
                <div class="receipt-header">
                  Associate : {{ receiptData.empId }}
                </div>
                <div class="receipt-header">
                  <b>Promised on: {{ receiptData.readyOn }}</b>
                </div>

                <div
                  class="receipt-style"
                  v-if="receiptData.defectReason != ''"
                >
                  Defect Reason : {{ receiptData.defectReason }}
                </div>
              </td>
            </tr>
          </table>
          <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr
              style="border-top: 1px solid gray; border-bottom: 1px solid gray"
            >
              <th>QTY</th>
              <th class="text-center">S/T/Descrition</th>
              <th>PRICE</th>
              <th style="text-align: right">TOTAL</th>
              <th style="text-align: right">STATUS</th>
            </tr>
            <template
              v-for="itemDetail in receiptData.itemsDetails"
              :key="itemDetail"
            >
              <tr :class="{service_row_cls: itemDetail.internalStatus == 'Parent'}">
                <td>{{ itemDetail.qty }}x</td>
                <td>
                  <small> {{ itemDetail.serviceName }} </small>
                </td>
                <td></td>
                <td style="text-align: right">
                  ${{ formatAmount(itemDetail.totalBill) }}
                </td>
                <td style="text-align: right">
                  {{ itemDetail.workingStatus.toUpperCase() }} /
                  {{ itemDetail.checkStatus }}
                </td>
              </tr>
              <template
                v-for="item in reverseReceipt(itemDetail.itemList)"
                :key="item"
              >
                <tr style="height: 35px">
                  <td>
                     <span
                      style="
                        background-color: #333;
                        padding: 2px;
                        color: #fff;
                        margin: 10px 0px;
                        border-radius: 2px;
                      "
                      >{{ item.itemQty }}x</span
                    >
                  </td>
                  <td>
                    <small>
                      <i class="fa fa-arrow-circle-o-right"></i>
                      <span v-html="item.itemName"></span>
                    </small>
                  </td>
                  <td>
                    ${{ formatAmount(item.price) }}
                  </td>
                  <td></td>
                  <td v-if="item.tax != 'not_exempt'" style="text-align: right">{{ item.tax }}</td>
                </tr>
                <tr
                  style="height: 20px; text-align: left"
                  v-if="item.tag3 != ''"
                >
                  <td colspan="5">
                    <small>{{ item.tag3 }}</small>
                  </td>
                </tr>
              </template>
              <tr
                style="height: 20px; text-align: left"
                v-if="itemDetail.tag1 != ''"
              >
                <td colspan="5">
                  <small> {{ itemDetail.tag1 }}</small>
                </td>
              </tr>
              <tr
                style="height: 35px; text-align: left"
                v-if="itemDetail.tag2 != ''"
              >
                <td colspan="5">
                  <small>{{ itemDetail.tag2 }}</small>
                </td>
              </tr>
              <tr
                style="background-color: #c00; color: #fff; text-align: left"
                v-if="itemDetail.express == 'express'"
              >
                <td colspan="5">
                  <small
                    >Express 15% | {{ formatDate(itemDetail.dueDate) }}
                    {{ formatTime(itemDetail.dueTime) }}</small
                  >
                </td>
              </tr>
              <tr v-if="itemDetail.description != ''">
                <td colspan="5">
                  <small>Memo : {{ itemDetail.description }}</small>
                </td>
              </tr>
              <tr  v-if="itemDetail.discountId != ''">
                <td colspan="5">
                  <small>Coupon : {{
                    itemDetail.discountName +
                    " " +
                    itemDetail.discountMethod +
                    " " +
                    itemDetail.discount
                  }}</small>
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="5">
                <div style="border-top: 1px dotted gray; margin-top: 5px"></div>
              </td>
            </tr>
          </table>
          <div class="receipt-style" v-if="receiptData.memo != ''">
            Memo {{ receiptData.memo }}
          </div>
          <div class="receipt-style">
            <b class="pull-left">{{ receiptData.taxNumber }}</b> Subtotal ($)
            {{ formatAmount(receiptData.subTotal)  }}
          </div>
          <div class="receipt-style" v-if="receiptData.discountAmt != 0">
            Coupon  {{ receiptData.discountName }} ({{ receiptData.discountMethod }})
            {{ formatAmount(receiptData.discountAmt) }}
          </div>
           <div class="receipt-style" v-if="receiptData.discountAmt != 0">
            Subtotal After Disc  ${{ formatAmount(receiptData.totalAfterDisc) }}
          </div>
          <div class="receipt-style">
             <span class="pull-left"> Total Disc : ${{formatAmount(receiptData.totalDiscount)}} </span>
          </div>
          <div class="receipt-style">

            <b v-if="receiptData.canadaExempt == 'yes'"> Fed Exempt : Yes </b> 
            <b v-if="receiptData.provinceExempt == 'yes'"> Prov Exempt : Yes </b> 
            {{ receiptData.taxCode }}
          </div>
          <div class="receiptstyle-bold">Total ($) {{ receiptData.total }}</div>
          <div class="receiptstyle-bold" v-if="receiptData.balance <= 0.2">
            Balance Owing ($) 0.00
          </div>
          <div class="receiptstyle-bold" v-if="receiptData.balance > 0.2">
            Balance Owing ($) {{ formatAmount(receiptData.balance) }}
          </div>
          <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
              <td>
                <span style="font-size: 20px; color: #c00"
                  >Production Date : {{ receiptData.prodDate }}</span
                >
              </td>
            </tr>
            <tr>
              <td><br /></td>
            </tr>
            <tr>
              <td class="text-center">
                <small>Transaction details: </small>
              </td>
            </tr>
            <tr>
              <td style="text-align: center; font-size: 15px">
                <small
                  >***************************************************</small
                >
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Item : <b>{{ receiptData.itemsTotal }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Tender Type : <b>{{ receiptData.paymentMethod }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Amount : <b>${{ receiptData.total }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Total Tendered : <b> ${{ receiptData.totalTendered }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Total Change : <b> ${{ receiptData.totalChange }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Round Off : <b> ${{ receiptData.totalRound }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Points Earned Before This Visit:<b>
                    ${{ receiptData.allNeedles }}</b
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="receipt-style">
                  Total Points Earned: <b>${{ receiptData.currentNeedles }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <small>Visit us online at www.stitchit.com</small>
              </td>
            </tr>
            <template
              v-for="statement in receiptData.statements"
              :key="statement"
            >
              <tr>
                <td class="text-center">
                  <small
                    >{{ statement.receiptHeading }}
                    <br />
                    {{ statement.receiptContent }}
                  </small>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <small
                    >***************************************************</small
                  >
                </td>
              </tr>
            </template>
            <tr>
              <td class="text-center">*** <sup>Customer Copy</sup> ***</td>
            </tr>
          </table>
          <!-- // CONTENT TABLE -->
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options,mixins } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Backroom from "../service/Backroom";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      if (obj.status) {
        this.receiptID = obj.receiptID;
        this.openDialog();
      }
    },
  },
  emits: ["updateReceiptStatus"],
})
export default class PreviewReceipt extends mixins(UtilityOptions){
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle;
  private receiptID;
  private backroomService;
  private statements = [];
  private receiptData = {
    address: "",
    allNeedles: "",
    balance: "",
    branchCode: "",
    branchName: "",
    currentNeedles: "",
    customer: "",
    customerId: "",
    customerTel: "",
    dateTime: "",
    defectReason: "",
    discountAmt: "",
    discountMethod: "",
    discountName: "",
    empId: "",
    employeeName: "",
    invPayStatus: "",
    itemsDetails: [
      {
        checkRedo: "",
        checkStatus: "",
        completedDate: "",
        completedTime: "",
        createdEmpId: "",
        date: "",
        description: "",
        discount: "",
        discountId: "",
        discountMethod: "",
        discountName: "",
        totalDiscountAmt: 0,
        dueDate: "",
        dueTime: "",
        express: "",
        expressAmt: "",
        expressNotification: "",
        id: "",
        invId: "",
        itemList: [
          {
            itemDetailCharges: "",
            itemId: "",
            itemName: "",
            itemQty: "",
            price: "",
            salesItemId: "",
            tag3: "",
            tax: "",
          },
        ],
        qty: "",
        serviceId: "",
        serviceName: "",
        startDate: "",
        startTime: "",
        storeId: "",
        tag1: "",
        tag2: "",
        tax: "",
        totalBill: 0,
        upchargeCharges: "",
        upchargeChargesPer: "",
        workingStatus: "",
      },
    ],
    itemsTotal: "",
    memo: "",
    paymentMethod: "",
    pricelist: "",
    prodDate: "",
    readyOn: "",
    statements: [
      {
        branchId: "",
        createdDate: "",
        priority: "",
        receiptContent: "",
        receiptHeading: "",
        receiptId: "",
        status: "",
        type: "",
      },
    ],
    receipt: "",
    receiptNo: "",
    tax: "",
    taxCode: "",
    taxName: "",
    taxNumber: "",
    taxAmountPrice: 0,
    telephone: "",
    total: "",
    totalChange: "",
    totalDiscount: 0,
    totalPaid: "",
    totalAfterDisc:"",
    subTotal:"",
    totalTendered: "",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.backroomService = new Backroom();
  }

  mounted() {
    //asdsd
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    //this.clearItem();
    this.$emit("updateReceiptStatus");
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    //this.clearItem();
    this.submitted = false;
    this.productDialog = true;
    this.dialogTitle = "Preview Receipt";
    this.fetchReceiptData();
  }

  fetchReceiptData() {
    this.backroomService.customerReceipt(this.receiptID).then((data) => {
      const receiptInfo = this.camelizeKeys(data.general_info);
      this.receiptData = receiptInfo;
    });
  }

  // camelizeKeys = (obj) => {
  //   if (Array.isArray(obj)) {
  //     return obj.map((v) => this.camelizeKeys(v));
  //   } else if (obj !== null && obj.constructor === Object) {
  //     return Object.keys(obj).reduce(
  //       (result, key) => ({
  //         ...result,
  //         [camelCase(key)]: this.camelizeKeys(obj[key]),
  //       }),
  //       {}
  //     );
  //   }
  //   return obj;
  // };

  reverseReceipt(itemList)
  {
    const l = itemList.slice();
    return l.reverse();
  }
}
</script>
<style scoped>
.receipt-header {
  color: #5f5f5f;
  font-size: 15px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 3px;
  text-align: left;
}
.receipt-style {
  text-align: right;
  font-size: 15px;
  margin: 0px 0px;
  color: #5f5f5f;
}

.receiptstyle-bold {
  text-align: right;
  font-size: 22px;
  color: #5f5f5f;
  font-weight: bold;
}

.service_row_cls
{
  background-color: #333; color: #fff; margin: 0px;
}


</style>
