<template>
  <Dialog
    v-model:visible="serviceDialog"
    :style="{ width: '100vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid  p-m-0 p-dialog-maximized"
    :showHeader="false"
  >
    <div class="p-d-flex p-jc-end p-mb-3">
      <div class="p-mr-2">
        <Button
          class="p-button-primary"
          icon="pi pi-check-circle"
          label="Done"
          @click="emitServiceQty('Done')"
        />
      </div>
      <div class="p-mr-2">
        <Button
          class="p-button-danger"
          icon="pi pi-times"
          label="Cancel"
          @click="emitServiceQty('Cancel')"
        />
      </div>
    </div>
   
    <div class="p-grid">
       <div style="height: 0.2em; background-color: #eee" class="p-p-0 p-col-12">
        <ProgressBar
          v-if="progressBar"
          mode="indeterminate"
          style="height: 0.2em"
        />
      </div>
      <div class="p-col-4">
        <h5 class="p-mt-3">
          <i class="pi pi-bars"></i>
          Unit & Scan Coupons
        </h5>
        <div class="p-field">
          <label for="Priority">Coupons</label>
          <Dropdown
            v-model="selectedCoupon"
            :options="couponList"
            optionLabel="discountBarcode"
            :filter="true"
            placeholder="Search Service Coupon"
            :showClear="true"
          >
            <template #option="slotProps">
              <div>
                <span class="coupon-name">
                  Name : {{ slotProps.option.discountName }} | Expiry :
                  {{ formatDate(slotProps.option.endDate) }}
                </span>
                <span class="coupon-code">
                  Barcode : {{ slotProps.option.discountBarcode }}
                </span>
                <span class="coupon-amount">
                  Discount : {{ slotProps.option.discount }} {{ slotProps.option.discountMethod }}
                </span>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="Priority">Unit</label>
          <div class="p-inputgroup">
            <Button
              @click="counter('inc')"
              icon="pi pi-plus-circle"
              class="p-button-primary p-button-lg"
            />
            <InputNumber v-model="itemQty" intergeronly />
            <Button
              @click="counter('dec')"
              icon="pi pi-minus-circle"
              class="p-button-danger p-button-lg"
            />
          </div>
        </div>
        <div class="p-field">
          <label for="Priority">Memo</label>
          <InputText
            v-model="serviceDesc"
            placeholder="any description for the service"
          />
        </div>
      </div>
      <div class="p-col-8 express-section">
        <div class="p-d-flex p-jc-between p-mt-3">
          <div>
            <h4>
              <i class="pi pi-star-o"></i>
              Is it Express ?
            </h4>
          </div>
          <div class="p-mb-2 p-mr-2 pull-right">
            <InputSwitch v-model="express" value="express" />
          </div>
        </div>
        <h5 class="p-col-12">
          <i class="pi pi-calendar"></i>
          Set Express Due Date & Time <span style="background-color:yellow" v-if="serviceExpressDate != ''">[{{formatDate(serviceExpressDate)}}]</span>
          <small class="coupon-amount pull-right">
            Available left capacity for express today is $
            {{ fixLength(expressAmount) }}</small
          >
        </h5>
        <div class="p-grid">
          <div class="p-col-9">
             <Calendar v-model="serviceExpressDate" :minDate="minDateValue" :inline="true"  :manualInput="false" dateFormat="yy-mm-dd" />
          </div>
          <div class="p-col-3">
            <h5 class="date-time-clock-hrs-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Available Hours
            </h5>
            <div class="clock-hours-timming">
              <h5 @click="setDueTime('09:00')" class="date-time-clock-hrs">
                09:00 AM
              </h5>
              <h5 @click="setDueTime('09:15')" class="date-time-clock-hrs">
                09:15 AM
              </h5>
              <h5 @click="setDueTime('09:30')" class="date-time-clock-hrs">
                09:30 AM
              </h5>
              <h5 @click="setDueTime('09:45')" class="date-time-clock-hrs">
                09:45 AM
              </h5>
              <h5 @click="setDueTime('10:00')" class="date-time-clock-hrs">
                10:00 AM
              </h5>
              <h5 @click="setDueTime('10:15')" class="date-time-clock-hrs">
                10:15 AM
              </h5>
              <h5 @click="setDueTime('10:30')" class="date-time-clock-hrs">
                10:30 AM
              </h5>
              <h5 @click="setDueTime('10:45')" class="date-time-clock-hrs">
                10:45 AM
              </h5>
              <h5 @click="setDueTime('11:00')" class="date-time-clock-hrs">
                11:00 AM
              </h5>
              <h5 @click="setDueTime('11:15')" class="date-time-clock-hrs">
                11:15 AM
              </h5>
              <h5 @click="setDueTime('11:30')" class="date-time-clock-hrs">
                11:30 AM
              </h5>
              <h5 @click="setDueTime('11:45')" class="date-time-clock-hrs">
                11:45 AM
              </h5>
              <h5 @click="setDueTime('12:00')" class="date-time-clock-hrs">
                12:00 PM
              </h5>
              <h5 @click="setDueTime('12:15')" class="date-time-clock-hrs">
                12:15 PM
              </h5>
              <h5 @click="setDueTime('12:30')" class="date-time-clock-hrs">
                12:30 PM
              </h5>
              <h5 @click="setDueTime('12:45')" class="date-time-clock-hrs">
                12:45 PM
              </h5>
              <h5 @click="setDueTime('13:00')" class="date-time-clock-hrs">
                01:00 PM
              </h5>
              <h5 @click="setDueTime('13:15')" class="date-time-clock-hrs">
                01:15 PM
              </h5>
              <h5 @click="setDueTime('13:30')" class="date-time-clock-hrs">
                01:30 PM
              </h5>
              <h5 @click="setDueTime('13:45')" class="date-time-clock-hrs">
                01:45 PM
              </h5>
              <h5 @click="setDueTime('14:00')" class="date-time-clock-hrs">
                02:00 PM
              </h5>
              <h5 @click="setDueTime('14:15')" class="date-time-clock-hrs">
                02:15 PM
              </h5>
              <h5 @click="setDueTime('14:30')" class="date-time-clock-hrs">
                02:30 PM
              </h5>
              <h5 @click="setDueTime('14:45')" class="date-time-clock-hrs">
                02:45 PM
              </h5>
              <h5 @click="setDueTime('15:00')" class="date-time-clock-hrs">
                03:00 PM
              </h5>
               <h5 @click="setDueTime('15:15')" class="date-time-clock-hrs">
                03:15 PM
              </h5>
               <h5 @click="setDueTime('15:30')" class="date-time-clock-hrs">
                03:30 PM
              </h5>
               <h5 @click="setDueTime('15:45')" class="date-time-clock-hrs">
                03:45 PM
              </h5>
              <h5 @click="setDueTime('16:00')" class="date-time-clock-hrs">
                04:00 PM
              </h5>
               <h5 @click="setDueTime('16:15')" class="date-time-clock-hrs">
                04:15 PM
              </h5>
               <h5 @click="setDueTime('16:30')" class="date-time-clock-hrs">
                04:30 PM
              </h5>
               <h5 @click="setDueTime('16:45')" class="date-time-clock-hrs">
                04:45 PM
              </h5>
              <h5 @click="setDueTime('17:00')" class="date-time-clock-hrs">
                05:00 PM
              </h5>
              <h5 @click="setDueTime('17:15')" class="date-time-clock-hrs">
                05:15 PM
              </h5>
              <h5 @click="setDueTime('17:30')" class="date-time-clock-hrs">
                05:30 PM
              </h5>
              <h5 @click="setDueTime('17:45')" class="date-time-clock-hrs">
                05:45 PM
              </h5>
              <h5 @click="setDueTime('18:00')" class="date-time-clock-hrs">
                06:00 PM
              </h5>
              <h5 @click="setDueTime('18:15')" class="date-time-clock-hrs">
                06:15 PM
              </h5>
              <h5 @click="setDueTime('18:30')" class="date-time-clock-hrs">
                06:30 PM
              </h5>
              <h5 @click="setDueTime('18:45')" class="date-time-clock-hrs">
                06:45 PM
              </h5>
              <h5 @click="setDueTime('19:00')" class="date-time-clock-hrs">
                07:00 PM
              </h5>
               <h5 @click="setDueTime('19:15')" class="date-time-clock-hrs">
                07:15 PM
              </h5>
               <h5 @click="setDueTime('19:30')" class="date-time-clock-hrs">
                07:30 PM
              </h5>
               <h5 @click="setDueTime('19:45')" class="date-time-clock-hrs">
                07:45 PM
              </h5>
              <h5 @click="setDueTime('20:00')" class="date-time-clock-hrs">
                08:00 PM
              </h5>
               <h5 @click="setDueTime('20:15')" class="date-time-clock-hrs">
                08:15 PM
              </h5>
               <h5 @click="setDueTime('20:30')" class="date-time-clock-hrs">
                08:30 PM
              </h5>
               <h5 @click="setDueTime('20:45')" class="date-time-clock-hrs">
                08:45 PM
              </h5>
              <h5 @click="setDueTime('21:00')" class="date-time-clock-hrs">
                09:00 PM
              </h5>
            </div>
            <input
              type="time"
              name="get_the_time"
              id="get_the_time"
              class="set_the_time_value form-control"
              v-model="serviceExpressTime"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";
import { CouponTypes } from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.selectedCoupon = {
        discountId: "",
        discountMethod: "",
        discount: 0,
        discountName: "",
        startsDate: "",
        endDate: "",
        discountBarcode: "",
        description: "",
      };

      this.discountId = obj.discountId;

      this.serviceDialog = obj.serviceDialog;
      this.serviceExpressDate = obj.serviceExpressDate;
      this.serviceExpressTime = obj.serviceExpressTime;
      this.serviceDesc = obj.serviceDesc;
      this.itemQty = obj.itemQty;
      this.expressAmount = obj.expressAmount;
      this.getCoupons(obj.couponList);

      if (obj.express == "express") {
        this.express = true;
      } else {
        this.express = false;
      }
    },
  },
  components: {},
  emits: ["emitServiceDetailEvent"],
})

export default class ServiceQty extends Vue {
  private toast;
  private serviceDialog = false;
  private expressAmount = 0;
  private itemQty = 0;
  private selectedCoupon = {
    discountId: "",
    discountMethod: "",
    discount: 0,
    discountName: "",
    startsDate: "",
    endDate: "",
    discountBarcode: "",
    description: "",
  };
  private discountId = "";
  private discountMethod = "";
  private discountName = "";
  private discount = "";
  private serviceExpressDate = "";
  private serviceDesc = "";
  private checkTxn;
  private serviceExpressTime = '';
  private express = false;
  private store = useStore();
  private options = ["Off", "On"];
  private couponList: CouponTypes[] = [];

  private priceList = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(value) {
    return value.toFixed(2);
  }

  get minDateValue() {
    return new Date();
  }

  setDueTime(dueTime) {
    if (this.express == true) {
      this.serviceExpressTime = dueTime;
    } else {
      this.toast.showInfo("Please enable  the express switch button");
      this.serviceExpressTime = '';
    }
  }

  emitServiceQty(action) {
    let express = "no";

    let discountId = "";
    let discountName = "";
    let discountMethod = "";
    let discount = 0;

    if (this.express == true) {
      express = "express";
    }
    else
    {
      express = "no";
    }

    if (this.selectedCoupon != null) {
      discountId = this.selectedCoupon.discountId;
      discountName = this.selectedCoupon.discountName;
      (discountMethod = this.selectedCoupon.discountMethod),
        (discount = this.selectedCoupon.discount);
    } 

    if(express == 'express' && (this.serviceExpressTime == '' || this.serviceExpressDate == ''))
    {
      this.toast.showWarning('Please choose the Express Date and Time');
    }
    else
    {
      const parseDate = moment(this.serviceExpressDate).format("YYYY-MM-DD");

      this.$emit("emitServiceDetailEvent", [
        action,
        this.itemQty,
        this.serviceDesc,
        this.serviceExpressTime,
        parseDate,
        express,
        discountId,
        discountName,
        discountMethod,
        discount,
      ]);
      this.serviceDialog = false;
    }
  }

  counter(action) {
    if (action == "inc") {
      this.itemQty++;
    } else {
      if (this.itemQty <= 0) {
        this.toast.showWarning("Cannot decrease the quatity less then 0");
      } else {
        this.itemQty--;
      }
    }
  }

  getCoupons(couponList) {
    this.couponList = [];

    couponList.forEach((e) => {
      if (e.id == this.discountId) {
        this.selectedCoupon = {
          discountId: e.id,
          discountName: e.discountName,
          discountMethod: e.discountMethod,
          discount: e.discountPercentage,
          startsDate: e.startsDate,
          endDate: e.endDate,
          discountBarcode: e.discountBarcode,
          description: e.description,
        };
      }

      const coup: CouponTypes = {
        discountId: e.id,
        discountName: e.discountName,
        discountMethod: e.discountMethod,
        discount: e.discountPercentage,
        startsDate: e.startsDate,
        endDate: e.endDate,
        discountBarcode: e.discountBarcode,
        description: e.description,
      };
      this.couponList.push(coup);
    });
  }

  formatDate(value) {
    if (value) {
      return moment(value).format("DD-MM-YYYY");
    }
  }
}
</script>

<style scoped>
.express-section {
  background-color: #fbfbfb;
}

.coupon-name {
  background-color: #004c97;
  color: #fff;
  font-size: 18px;
  padding: 2px;
  border-radius: 3px;
}

.coupon-code {
  background-color: #000;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  padding: 2px;
}

.coupon-amount {
  background-color: #dc3545;
  color: #fff;
  border-radius: 3px;
  font-size: 18px;
  padding: 2px;
}
</style>
