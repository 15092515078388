
import { Options, Vue,mixins } from "vue-class-component";
import { useStore } from "../store";
import Transaction from "../service/Transaction";
import { camelCase } from "lodash";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  components: {},
})
export default class ReceiptHistory extends mixins(UtilityOptions){
  private store = useStore();
  private txnService;
  private toast;
  private receiptItems = [
    {
      date: "",
      time: "",
      itemName: "",
      totalBill: 0,
      employeeId: "",
      status: "",
    },
  ];

  private paymentHistory = [
    {
      date: "",
      sales: "",
      proceed: 0,
      creditNote: 0,
      description: "",
      extraInfo: "",
      method: "",
    },
  ];

  created() {
    this.txnService = new Transaction();
    this.toast = new Toaster();
  }

  get receiptID() {
    return this.store.getters.getReceiptID;
  }

  mounted() {
    this.getReceiptHistory();
  }

  getReceiptHistory() {
    this.txnService.receiptHistory(this.receiptID).then((data) => {
      const itemsHistory = this.camelizeKeys(data);
      this.receiptItems = itemsHistory.processDetails;
      this.paymentHistory = itemsHistory.transactionHistory;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  totalBalance(history) {
    let totalBalance = 0;

    const total =
      Number(history.sales) -
      Number(history.proceed) -
      Number(history.creditNote);

    if (history.description == "EDIT") {
      const oldBalance = totalBalance;
      totalBalance = totalBalance + total;
      totalBalance = totalBalance - oldBalance;
    } else {
      totalBalance = totalBalance + total;
    }

    if (totalBalance < 0.03) {
      totalBalance = 0.0;
    }

    return totalBalance.toFixed(2);
  }
}
