
import { Options,mixins } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Backroom from "../service/Backroom";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      if (obj.status) {
        this.receiptID = obj.receiptID;
        this.openDialog();
      }
    },
  },
  emits: ["updateReceiptStatus"],
})
export default class PreviewReceipt extends mixins(UtilityOptions){
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle;
  private receiptID;
  private backroomService;
  private statements = [];
  private receiptData = {
    address: "",
    allNeedles: "",
    balance: "",
    branchCode: "",
    branchName: "",
    currentNeedles: "",
    customer: "",
    customerId: "",
    customerTel: "",
    dateTime: "",
    defectReason: "",
    discountAmt: "",
    discountMethod: "",
    discountName: "",
    empId: "",
    employeeName: "",
    invPayStatus: "",
    itemsDetails: [
      {
        checkRedo: "",
        checkStatus: "",
        completedDate: "",
        completedTime: "",
        createdEmpId: "",
        date: "",
        description: "",
        discount: "",
        discountId: "",
        discountMethod: "",
        discountName: "",
        totalDiscountAmt: 0,
        dueDate: "",
        dueTime: "",
        express: "",
        expressAmt: "",
        expressNotification: "",
        id: "",
        invId: "",
        itemList: [
          {
            itemDetailCharges: "",
            itemId: "",
            itemName: "",
            itemQty: "",
            price: "",
            salesItemId: "",
            tag3: "",
            tax: "",
          },
        ],
        qty: "",
        serviceId: "",
        serviceName: "",
        startDate: "",
        startTime: "",
        storeId: "",
        tag1: "",
        tag2: "",
        tax: "",
        totalBill: 0,
        upchargeCharges: "",
        upchargeChargesPer: "",
        workingStatus: "",
      },
    ],
    itemsTotal: "",
    memo: "",
    paymentMethod: "",
    pricelist: "",
    prodDate: "",
    readyOn: "",
    statements: [
      {
        branchId: "",
        createdDate: "",
        priority: "",
        receiptContent: "",
        receiptHeading: "",
        receiptId: "",
        status: "",
        type: "",
      },
    ],
    receipt: "",
    receiptNo: "",
    tax: "",
    taxCode: "",
    taxName: "",
    taxNumber: "",
    taxAmountPrice: 0,
    telephone: "",
    total: "",
    totalChange: "",
    totalDiscount: 0,
    totalPaid: "",
    totalAfterDisc:"",
    subTotal:"",
    totalTendered: "",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.backroomService = new Backroom();
  }

  mounted() {
    //asdsd
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    //this.clearItem();
    this.$emit("updateReceiptStatus");
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    //this.clearItem();
    this.submitted = false;
    this.productDialog = true;
    this.dialogTitle = "Preview Receipt";
    this.fetchReceiptData();
  }

  fetchReceiptData() {
    this.backroomService.customerReceipt(this.receiptID).then((data) => {
      const receiptInfo = this.camelizeKeys(data.general_info);
      this.receiptData = receiptInfo;
    });
  }

  // camelizeKeys = (obj) => {
  //   if (Array.isArray(obj)) {
  //     return obj.map((v) => this.camelizeKeys(v));
  //   } else if (obj !== null && obj.constructor === Object) {
  //     return Object.keys(obj).reduce(
  //       (result, key) => ({
  //         ...result,
  //         [camelCase(key)]: this.camelizeKeys(obj[key]),
  //       }),
  //       {}
  //     );
  //   }
  //   return obj;
  // };

  reverseReceipt(itemList)
  {
    const l = itemList.slice();
    return l.reverse();
  }
}
