
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";
import { CouponTypes } from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.selectedCoupon = {
        discountId: "",
        discountMethod: "",
        discount: 0,
        discountName: "",
        startsDate: "",
        endDate: "",
        discountBarcode: "",
        description: "",
      };

      this.discountId = obj.discountId;

      this.serviceDialog = obj.serviceDialog;
      this.serviceExpressDate = obj.serviceExpressDate;
      this.serviceExpressTime = obj.serviceExpressTime;
      this.serviceDesc = obj.serviceDesc;
      this.itemQty = obj.itemQty;
      this.expressAmount = obj.expressAmount;
      this.getCoupons(obj.couponList);

      if (obj.express == "express") {
        this.express = true;
      } else {
        this.express = false;
      }
    },
  },
  components: {},
  emits: ["emitServiceDetailEvent"],
})

export default class ServiceQty extends Vue {
  private toast;
  private serviceDialog = false;
  private expressAmount = 0;
  private itemQty = 0;
  private selectedCoupon = {
    discountId: "",
    discountMethod: "",
    discount: 0,
    discountName: "",
    startsDate: "",
    endDate: "",
    discountBarcode: "",
    description: "",
  };
  private discountId = "";
  private discountMethod = "";
  private discountName = "";
  private discount = "";
  private serviceExpressDate = "";
  private serviceDesc = "";
  private checkTxn;
  private serviceExpressTime = '';
  private express = false;
  private store = useStore();
  private options = ["Off", "On"];
  private couponList: CouponTypes[] = [];

  private priceList = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(value) {
    return value.toFixed(2);
  }

  get minDateValue() {
    return new Date();
  }

  setDueTime(dueTime) {
    if (this.express == true) {
      this.serviceExpressTime = dueTime;
    } else {
      this.toast.showInfo("Please enable  the express switch button");
      this.serviceExpressTime = '';
    }
  }

  emitServiceQty(action) {
    let express = "no";

    let discountId = "";
    let discountName = "";
    let discountMethod = "";
    let discount = 0;

    if (this.express == true) {
      express = "express";
    }
    else
    {
      express = "no";
    }

    if (this.selectedCoupon != null) {
      discountId = this.selectedCoupon.discountId;
      discountName = this.selectedCoupon.discountName;
      (discountMethod = this.selectedCoupon.discountMethod),
        (discount = this.selectedCoupon.discount);
    } 

    if(express == 'express' && (this.serviceExpressTime == '' || this.serviceExpressDate == ''))
    {
      this.toast.showWarning('Please choose the Express Date and Time');
    }
    else
    {
      const parseDate = moment(this.serviceExpressDate).format("YYYY-MM-DD");

      this.$emit("emitServiceDetailEvent", [
        action,
        this.itemQty,
        this.serviceDesc,
        this.serviceExpressTime,
        parseDate,
        express,
        discountId,
        discountName,
        discountMethod,
        discount,
      ]);
      this.serviceDialog = false;
    }
  }

  counter(action) {
    if (action == "inc") {
      this.itemQty++;
    } else {
      if (this.itemQty <= 0) {
        this.toast.showWarning("Cannot decrease the quatity less then 0");
      } else {
        this.itemQty--;
      }
    }
  }

  getCoupons(couponList) {
    this.couponList = [];

    couponList.forEach((e) => {
      if (e.id == this.discountId) {
        this.selectedCoupon = {
          discountId: e.id,
          discountName: e.discountName,
          discountMethod: e.discountMethod,
          discount: e.discountPercentage,
          startsDate: e.startsDate,
          endDate: e.endDate,
          discountBarcode: e.discountBarcode,
          description: e.description,
        };
      }

      const coup: CouponTypes = {
        discountId: e.id,
        discountName: e.discountName,
        discountMethod: e.discountMethod,
        discount: e.discountPercentage,
        startsDate: e.startsDate,
        endDate: e.endDate,
        discountBarcode: e.discountBarcode,
        description: e.description,
      };
      this.couponList.push(coup);
    });
  }

  formatDate(value) {
    if (value) {
      return moment(value).format("DD-MM-YYYY");
    }
  }
}
