
import { Options, mixins } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import BuyBackService from "../service/BuyBackService";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../components/PaymentScreen.vue";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  inheritAttrs: false,
  components: {
    PaymentScreen,
  },
  emits: ["searchReceiptEvent", "updateRedoReceipt", "clearCheckOutEvent"],
})
export default class RefundReceipt extends mixins(UtilityOptions){
  private dialogTilte = " Refund Payments";
  private txnService;
  private referralOrCorp = "";
  private customerID = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private paymentDialog = false;
  private submitted = false;
  private imagePath = "";
  private toast;
  private vuexStore = useStore();
  private orderDetail = {
    dueDate: "",
    dueTime: "",
    description: "",
    cusId: "",
  };

  private receiptPayments = [
    {
      paymentType: "",
      transTotalAmount: 0,
    },
  ];

  private checkedItems: string[] = [];

  private orderItems = [
    {
      express: "",
      image: "",
      serviceName: "",
      totalBill: 0,
      saleItemId: "",
      checkStatus: "",
      workingStatus: "",
      description: "",
      dueDate: "",
      dueTime: "",
    },
  ];

  private refundItems = {
    id: "",
    totalToPaid: 0,
    reason: "Quality",
    employeeId: "",
    managerWhoApproved: "",
  };

  get receiptID() {
    return this.vuexStore.getters.getReceiptID;
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new BuyBackService();
    this.toast = new Toaster();
    this.imagePath = this.txnService.getBaseURL() + "uploads/services/";
  }

  mounted() {
    this.getReceiptBB();
  }

  getReceiptBB() {
    this.txnService.getBBDetails(this.receiptID).then((data) => {
      const itemsDetail = this.camelizeKeys(data);
      this.receiptPayments = itemsDetail.orderDetails.invoicePayment;
      this.orderDetail = itemsDetail.orderDetails;
      this.orderItems = itemsDetail.orderItems;
      this.customerID = itemsDetail.orderDetails.cusId;
      this.customerName = itemsDetail.orderDetails.customer;
      this.CustomerNeedles = itemsDetail.customerDetails.custStoreCredit;
      this.referralOrCorp = itemsDetail.orderDetails.referalOrCorp;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  proceedNext() {
    this.submitted = true;

    if (this.refundItems.totalToPaid <= 0) {
      this.toast.showError(
        "Invalid refund amount. Amount must be greater then 0"
      );
    } else if (this.refundItems.employeeId == "") {
      this.toast.showError("Please enter pin of associate of store");
    } else {
      this.verfiyManagerAndAssociatePin();
    }
  }

  verfiyManagerAndAssociatePin() {
    this.txnService
      .verifyPINS(
        this.refundItems.managerWhoApproved,
        this.refundItems.employeeId
      )
      .then((res) => {
        this.toast.handleResponse(res);

        if (res.alert == "info") {
          //OPEN PAYMENT SCREEN
          this.vuexStore.dispatch(
            ActionTypes.TOTAL_BILL,
            this.refundItems.totalToPaid
          );
          this.paymentDialog = true;
        }
      });
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    const drawerStatus = this.shouldOpenDrawer(paymentList);

    this.txnService
      .sendRefundReq(
        this.refundItems,
        paymentList,
        this.checkedItems,
        this.receiptID,
        this.refundItems.totalToPaid,
        drawerStatus
      )
      .then((res) => {
        if (res.alert == "info") {
          this.$emit("clearCheckOutEvent");
        }

        this.toast.handleResponse(res);
      });
  }

  get calculateRefund() {
    let totalBill = 0;

    this.orderItems.forEach((e) => {
      this.checkedItems.forEach((f) => {
        if (e.saleItemId == f) {
          totalBill = totalBill + Number(e.totalBill);
        }
      });
    });

    this.refundItems.totalToPaid = Number(totalBill-this.reduceNeedleAmt);
    return this.formatAmount(totalBill-this.reduceNeedleAmt);
  }

  get needleAmount() {
    let amount = 0;

    this.receiptPayments.forEach((e) => {
      if (e.paymentType == "Needle") {
        amount = amount + e.transTotalAmount;
      }
    });

    return Number(amount);
  }

  get reduceNeedleAmt()
  {
    const checkedItems = this.checkedItems.length;
    const itemsQty = this.orderItems.length;
    const needle = this.needleAmount;
    let  avgNeedles = 0;

    if(itemsQty != 0)
    {
      avgNeedles = needle / itemsQty;
    }
    return avgNeedles * checkedItems;
  }
}
