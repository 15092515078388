<template>
  <div class="history-set-height">
    <div class="p-0">
      <h4 class="checkout-heading">
        <i class="pi pi-file-o"></i><b> Order ID : {{ receiptID }} </b>
      </h4>
      <h5 class="mt-3 mb-3 text-center">
        Invoice Transaction & Process History
      </h5>
      <div class="col-md-12 p-0">
        <h4 class="checkout-heading">
          <i class="pi pi-file-o"></i> Invoice Transaction History
        </h4>
        <table class="table table-bordered text-center table-striped">
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Service Name</th>
            <th>Total</th>
            <th>Associate</th>
            <th>Status</th>
          </tr>
          <tr v-for="process in receiptItems" :key="process">
            <td>{{ formatDate(process.date) }}</td>
            <td>{{ formatTime(process.time) }}</td>
            <td style="width: 12vw">{{ process.itemName }}</td>
            <td>$ {{ formatAmount(process.totalBill) }}</td>
            <td>{{ process.employeeId }}</td>
            <td>{{ process.status.toUpperCase() }}</td>
          </tr>
        </table>

        <h4 class="checkout-heading">
          <i class="pi pi-money-bill"></i> Invoice Payment History
        </h4>
        <table class="table table-bordered text-center table-striped">
          <tr>
            <th>Date</th>
            <th>Sales</th>
            <th>Procceds</th>
            <th>Cr Note</th>
            <th>Desc</th>
            <th>Method</th>
            <th>Balance</th>
          </tr>
          <template  v-for="history in paymentHistory" :key="history">
            <tr :class="{payoutInStore: history.extraInfo  == 'Yes'}" >
                <td>{{ formatDate(history.date) }}</td>
                <td>${{ formatAmount(history.sales) }}</td>
                <td>${{ formatAmount(history.proceed) }}</td>
                <td>${{ formatAmount(history.creditNote) }}</td>
                <td>{{ history.description }}</td>
                <td>{{ history.method }}</td>
                <td>${{ totalBalance(history) }}</td>
            </tr>
           </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue,mixins } from "vue-class-component";
import { useStore } from "../store";
import Transaction from "../service/Transaction";
import { camelCase } from "lodash";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  components: {},
})
export default class ReceiptHistory extends mixins(UtilityOptions){
  private store = useStore();
  private txnService;
  private toast;
  private receiptItems = [
    {
      date: "",
      time: "",
      itemName: "",
      totalBill: 0,
      employeeId: "",
      status: "",
    },
  ];

  private paymentHistory = [
    {
      date: "",
      sales: "",
      proceed: 0,
      creditNote: 0,
      description: "",
      extraInfo: "",
      method: "",
    },
  ];

  created() {
    this.txnService = new Transaction();
    this.toast = new Toaster();
  }

  get receiptID() {
    return this.store.getters.getReceiptID;
  }

  mounted() {
    this.getReceiptHistory();
  }

  getReceiptHistory() {
    this.txnService.receiptHistory(this.receiptID).then((data) => {
      const itemsHistory = this.camelizeKeys(data);
      this.receiptItems = itemsHistory.processDetails;
      this.paymentHistory = itemsHistory.transactionHistory;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  totalBalance(history) {
    let totalBalance = 0;

    const total =
      Number(history.sales) -
      Number(history.proceed) -
      Number(history.creditNote);

    if (history.description == "EDIT") {
      const oldBalance = totalBalance;
      totalBalance = totalBalance + total;
      totalBalance = totalBalance - oldBalance;
    } else {
      totalBalance = totalBalance + total;
    }

    if (totalBalance < 0.03) {
      totalBalance = 0.0;
    }

    return totalBalance.toFixed(2);
  }
}
</script>

<style scoped>
.border-btm {
  border-bottom: 1px solid #eee;
  margin-top: 5px;
  margin-bottom: 15px;
  display: block;
}

.history-set-height {
  height: 90.5vh;
  min-height: 90.5vh;
  overflow-y: scroll;
}

table tr td,
th {
  padding: 4px;
}

.payoutInStore
{
  background-color: #c82333;
  color:#fff;
}
</style>
